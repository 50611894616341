.editor {
  @apply font-sans;

  a:not(.button):not(.quiet) {
    @apply text-secondary;

    &:hover {
      @apply underline underline-offset-2 duration-300;
    }
  }

  ul:not(.sexy-list),
  ol {
    @apply list-none pl-0;

    li {
      @apply mb-2 last:mb-0;
    }
  }

  ul:not(.sexy-list):not(.nav) {
    li {
      @apply relative pl-5;

      &::before {
        @apply content-[""] absolute top-[0.5em] left-0 w-[0.4em] h-[0.4em] bg-primary rounded-full;
      }
    }

    ul {
      @apply pt-2;
    }
  }

  ol {
    counter-reset: ol;

    li {
      &::before {
        @apply pr-2;
      }
    }

    > li {
      counter-increment: ol;

      &::before {
        content: counter(ol) ".";
      }
    }

    ol {
      @apply pt-2;

      counter-reset: ol2;

      > li {
        counter-increment: ol2;

        @apply pl-5;

        &::before {
          content: counter(ol) "." counter(ol2) ".";
        }
      }

      ol {
        counter-reset: ol3;

        > li {
          counter-increment: ol3;

          @apply pl-10;

          &::before {
            content: counter(ol) "." counter(ol2) "." counter(ol3) ".";
          }
        }
      }
    }
  }

  .menu-item {
    @apply pl-0 before:hidden mr-2;
  }

  blockquote {
    @apply border-l border-primary/20 pt-3 pb-3 pl-5;
  }

  .aligncenter {
    @apply mx-auto;
  }

  .alignleft,
  .alignright {
    max-width: 50%;
  }

  .alignleft {
    @apply float-left mr-5;
  }

  .alignright {
    @apply float-right ml-5;
  }

  figcaption {
    @apply text-xs mt-1;
  }

  table {
    @apply w-full border-collapse;

    td,
    th {
      @apply text-left border border-primary/20 p-5;
    }

    th {
      @apply font-bold;
    }

    > tr:first-child td,
    > tr:first-child th,
    > tbody:first-child tr:first-child td,
    > tbody:first-child tr:first-child th {
      @apply bg-primary/10 font-bold;
    }
  }

  hr {
    @apply border-primary/20 my-8;
  }
  img{
	@apply rounded-lg;
  }

  & > p:not(:empty),
  & > ul:not(:empty),
  & > ol:not(:empty),
  & > blockquote:not(:empty),
  & > table,
  & > img,
  & > figure {
    @apply my-5 first:mt-0 last:mb-0;
  }

  h2 {
    &:not([class]) {
      @apply  text-primary my-8 text-2xl font-bold first:mt-0 last:mb-0 lg:text-3xl xl:text-4xl;
    }
  }

  h3 {
    &:not([class]) {
      @apply text-primary my-6 text-xl font-bold first:mt-0 last:mb-0 xl:text-2xl;
    }
  }

  h4 {
    &:not([class]) {
      @apply my-5 text-lg font-bold first:mt-0 last:mb-0 lg:text-xl;
    }
  }
}
