@tailwind base;
@tailwind components;
@tailwind utilities;

$sl-overlay-background: theme('colors.dark');
$sl-font-family: system-ui;
$sl-navigation-color: #fff;
@import '../node_modules/tiny-slider/dist/tiny-slider.css';
@import '../node_modules/simplelightbox/src/simple-lightbox.scss';
@import 'typo.scss';


/* GENERAL */
body {
	color: red;
	color: theme("colors.body");
	background: theme("colors.white");
	font-size: theme("fontSize.base");

	--fui-font: theme('fontFamily.sans');
	--fui-primary-color: theme("colors.primary");
	--fui-primary-color-hover: theme("colors.dark");
	--fui-border-radius:5px;
	--fui-gray-700:theme("colors.dark");
	--fui-label-font-size: theme('fontSize.base');
	--fui-label-font-weight: 400;
	--fui-btn-font-size: theme("fontSize.lg");

	@media (min-width: theme("screens.lg")) {
		@apply text-lg;
	}
  
	::selection {
	  background-color: theme("colors.light");
	}
}

/* GRID */
.gr{
	@apply grid grid-cols-12 gap-x-2 gap-y-5 md:gap-10;
}

/* CONTAINER */
.ctn {
	@apply px-[20px];
  
	@media (min-width: theme("screens.sm")) {
	  @apply px-[7vw];
	}
  
	@media (min-width: 1600px) {
	  padding-left: calc((100vw - 1352px) / 2);
	  padding-right: calc((100vw - 1352px) / 2);
	}
  }

/* SVG */
svg {
	height: auto;
	max-width: 100%;
}

/* SLIDESHOW */
.tns-ovh{
	overflow: visible !important;
}
.sl-wrapper .sl-image{
	@apply rounded-lg overflow-hidden;
}

/* FORM */

.fui-field:not(.fui-type-hidden-field) {
    min-width: 250px;
}
