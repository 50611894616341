.tns-outer {
  padding: 0 !important;
}

.tns-outer [hidden] {
  display: none !important;
}

.tns-outer [aria-controls], .tns-outer [data-action] {
  cursor: pointer;
}

.tns-slider {
  transition: all;
}

.tns-slider > .tns-item {
  box-sizing: border-box;
}

.tns-horizontal.tns-subpixel {
  white-space: nowrap;
}

.tns-horizontal.tns-subpixel > .tns-item {
  vertical-align: top;
  white-space: normal;
  display: inline-block;
}

.tns-horizontal.tns-no-subpixel:after {
  content: "";
  clear: both;
  display: table;
}

.tns-horizontal.tns-no-subpixel > .tns-item {
  float: left;
}

.tns-horizontal.tns-carousel.tns-no-subpixel > .tns-item {
  margin-right: -100%;
}

.tns-no-calc {
  position: relative;
  left: 0;
}

.tns-gallery {
  min-height: 1px;
  position: relative;
  left: 0;
}

.tns-gallery > .tns-item {
  transition: transform, opacity;
  position: absolute;
  left: -100%;
}

.tns-gallery > .tns-slide-active {
  position: relative;
  left: auto !important;
}

.tns-gallery > .tns-moving {
  transition: all .25s;
}

.tns-autowidth {
  display: inline-block;
}

.tns-lazy-img {
  opacity: .6;
  transition: opacity .6s;
}

.tns-lazy-img.tns-complete {
  opacity: 1;
}

.tns-ah {
  transition: height;
}

.tns-ovh {
  overflow: hidden;
}

.tns-visually-hidden {
  position: absolute;
  left: -10000em;
}

.tns-transparent {
  opacity: 0;
  visibility: hidden;
}

.tns-fadeIn {
  opacity: 1;
  filter: alpha(opacity= 100);
  z-index: 0;
}

.tns-normal, .tns-fadeOut {
  opacity: 0;
  filter: alpha(opacity= 0);
  z-index: -1;
}

.tns-vpfix {
  white-space: nowrap;
}

.tns-vpfix > div, .tns-vpfix > li {
  display: inline-block;
}

.tns-t-subp2 {
  width: 310px;
  height: 10px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
}

.tns-t-ct {
  width: -webkit-calc(100% * 70 / 3);
  width: -moz-calc(100% * 70 / 3);
  width: 2333.33%;
  position: absolute;
  right: 0;
}

.tns-t-ct:after {
  content: "";
  clear: both;
  display: table;
}

.tns-t-ct > div {
  width: -webkit-calc(100% / 70);
  width: -moz-calc(100% / 70);
  float: left;
  width: 1.42857%;
  height: 10px;
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: davis-sans, sans-serif;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

[type="text"], input:where(:not([type])), [type="email"], [type="url"], [type="password"], [type="number"], [type="date"], [type="datetime-local"], [type="month"], [type="search"], [type="tel"], [type="time"], [type="week"], [multiple], textarea, select {
  appearance: none;
  --tw-shadow: 0 0 #0000;
  background-color: #fff;
  border-width: 1px;
  border-color: #6b7280;
  border-radius: 0;
  padding: .5rem .75rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

[type="text"]:focus, input:where(:not([type])):focus, [type="email"]:focus, [type="url"]:focus, [type="password"]:focus, [type="number"]:focus, [type="date"]:focus, [type="datetime-local"]:focus, [type="month"]:focus, [type="search"]:focus, [type="tel"]:focus, [type="time"]:focus, [type="week"]:focus, [multiple]:focus, textarea:focus, select:focus {
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  border-color: #2563eb;
  outline: 2px solid #0000;
}

input::placeholder, textarea::placeholder {
  color: #6b7280;
  opacity: 1;
}

::-webkit-datetime-edit-fields-wrapper {
  padding: 0;
}

::-webkit-date-and-time-value {
  text-align: inherit;
  min-height: 1.5em;
}

::-webkit-datetime-edit {
  display: inline-flex;
}

::-webkit-datetime-edit {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-year-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-month-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-day-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-hour-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-minute-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-second-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-millisecond-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-meridiem-field {
  padding-top: 0;
  padding-bottom: 0;
}

select {
  print-color-adjust: exact;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right .5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
}

[multiple], [size]:where(select:not([size="1"])) {
  background-image: initial;
  background-position: initial;
  background-repeat: unset;
  background-size: initial;
  print-color-adjust: unset;
  padding-right: .75rem;
}

[type="checkbox"], [type="radio"] {
  appearance: none;
  print-color-adjust: exact;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  color: #2563eb;
  --tw-shadow: 0 0 #0000;
  background-color: #fff;
  background-origin: border-box;
  border-width: 1px;
  border-color: #6b7280;
  flex-shrink: 0;
  width: 1rem;
  height: 1rem;
  padding: 0;
  display: inline-block;
}

[type="checkbox"] {
  border-radius: 0;
}

[type="radio"] {
  border-radius: 100%;
}

[type="checkbox"]:focus, [type="radio"]:focus {
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 2px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  outline: 2px solid #0000;
}

[type="checkbox"]:checked, [type="radio"]:checked {
  background-color: currentColor;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-color: #0000;
}

[type="checkbox"]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
}

@media (forced-colors: active) {
  [type="checkbox"]:checked {
    appearance: auto;
  }
}

[type="radio"]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
}

@media (forced-colors: active) {
  [type="radio"]:checked {
    appearance: auto;
  }
}

[type="checkbox"]:checked:hover, [type="checkbox"]:checked:focus, [type="radio"]:checked:hover, [type="radio"]:checked:focus {
  background-color: currentColor;
  border-color: #0000;
}

[type="checkbox"]:indeterminate {
  background-color: currentColor;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 16'%3e%3cpath stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 8h8'/%3e%3c/svg%3e");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-color: #0000;
}

@media (forced-colors: active) {
  [type="checkbox"]:indeterminate {
    appearance: auto;
  }
}

[type="checkbox"]:indeterminate:hover, [type="checkbox"]:indeterminate:focus {
  background-color: currentColor;
  border-color: #0000;
}

[type="file"] {
  background: unset;
  border-color: inherit;
  font-size: unset;
  line-height: inherit;
  border-width: 0;
  border-radius: 0;
  padding: 0;
}

[type="file"]:focus {
  outline: 1px solid ButtonText;
  outline: 1px auto -webkit-focus-ring-color;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

@media (width >= 640px) {
  .container {
    max-width: 640px;
  }
}

@media (width >= 768px) {
  .container {
    max-width: 768px;
  }
}

@media (width >= 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (width >= 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (width >= 1536px) {
  .container {
    max-width: 1536px;
  }
}

.sr-only {
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.pointer-events-none {
  pointer-events: none;
}

.pointer-events-auto {
  pointer-events: auto;
}

.visible {
  visibility: visible;
}

.collapse {
  visibility: collapse;
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.-left-5 {
  left: -1.25rem;
}

.bottom-0 {
  bottom: 0;
}

.bottom-1\/2 {
  bottom: 50%;
}

.left-0 {
  left: 0;
}

.left-1\/2 {
  left: 50%;
}

.right-0 {
  right: 0;
}

.right-3 {
  right: .75rem;
}

.top-0 {
  top: 0;
}

.top-3 {
  top: .75rem;
}

.top-full {
  top: 100%;
}

.-z-10 {
  z-index: -10;
}

.z-10 {
  z-index: 10;
}

.z-20 {
  z-index: 20;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.col-span-1 {
  grid-column: span 1 / span 1;
}

.col-span-12 {
  grid-column: span 12 / span 12;
}

.col-span-2 {
  grid-column: span 2 / span 2;
}

.col-span-8 {
  grid-column: span 8 / span 8;
}

.col-start-3 {
  grid-column-start: 3;
}

.col-start-4 {
  grid-column-start: 4;
}

.float-left {
  float: left;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-10 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.my-20 {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.-mt-1 {
  margin-top: -.25rem;
}

.-mt-4 {
  margin-top: -1rem;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.ml-2 {
  margin-left: .5rem;
}

.ml-5 {
  margin-left: 1.25rem;
}

.ml-auto {
  margin-left: auto;
}

.mr-10 {
  margin-right: 2.5rem;
}

.mr-2 {
  margin-right: .5rem;
}

.mr-5 {
  margin-right: 1.25rem;
}

.mt-1 {
  margin-top: .25rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mt-12 {
  margin-top: 3rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-20 {
  margin-top: 5rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mt-\[1px\] {
  margin-top: 1px;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.contents {
  display: contents;
}

.hidden {
  display: none;
}

.h-10 {
  height: 2.5rem;
}

.h-12 {
  height: 3rem;
}

.h-14 {
  height: 3.5rem;
}

.h-2 {
  height: .5rem;
}

.h-2\.5 {
  height: .625rem;
}

.h-20 {
  height: 5rem;
}

.h-5 {
  height: 1.25rem;
}

.h-8 {
  height: 2rem;
}

.h-\[100vh\] {
  height: 100vh;
}

.h-\[200\%\] {
  height: 200%;
}

.h-\[30vh\] {
  height: 30vh;
}

.h-\[3px\] {
  height: 3px;
}

.h-\[80px\] {
  height: 80px;
}

.h-auto {
  height: auto;
}

.h-full {
  height: 100%;
}

.min-h-\[400px\] {
  min-height: 400px;
}

.w-1\/2 {
  width: 50%;
}

.w-12 {
  width: 3rem;
}

.w-2 {
  width: .5rem;
}

.w-2\.5 {
  width: .625rem;
}

.w-20 {
  width: 5rem;
}

.w-32 {
  width: 8rem;
}

.w-5 {
  width: 1.25rem;
}

.w-6 {
  width: 1.5rem;
}

.w-8 {
  width: 2rem;
}

.w-\[200\%\] {
  width: 200%;
}

.w-full {
  width: 100%;
}

.w-screen {
  width: 100vw;
}

.min-w-\[500px\] {
  min-width: 500px;
}

.max-w-4xl {
  max-width: 56rem;
}

.max-w-\[250px\] {
  max-width: 250px;
}

.max-w-\[300px\] {
  max-width: 300px;
}

.max-w-none {
  max-width: none;
}

.flex-auto {
  flex: auto;
}

.shrink-0 {
  flex-shrink: 0;
}

.grow {
  flex-grow: 1;
}

.origin-center {
  transform-origin: center;
}

.-translate-x-1\/2 {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-1\/2 {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-full {
  --tw-translate-y: -100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-2 {
  --tw-translate-y: .5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-rotate-90 {
  --tw-rotate: -90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-90 {
  --tw-rotate: 90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-x-100 {
  --tw-scale-x: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.resize {
  resize: both;
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-1 {
  gap: min(.25rem, 10px);
}

.gap-10 {
  gap: 2.5rem;
}

.gap-3 {
  gap: .75rem;
}

.gap-5 {
  gap: 1.25rem;
}

.gap-y-5 {
  row-gap: 1.25rem;
}

.space-x-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.75rem * var(--tw-space-x-reverse));
  margin-left: calc(.75rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.25rem * var(--tw-space-x-reverse));
  margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.overflow-hidden {
  overflow: hidden;
}

.rounded {
  border-radius: 5px;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-lg {
  border-radius: 10px;
}

.border {
  border-width: 1px;
}

.border-t {
  border-top-width: 1px;
}

.border-dark\/10 {
  border-color: #3222491a;
}

.border-dark\/15 {
  border-color: #32224926;
}

.border-dark\/20 {
  border-color: #32224933;
}

.border-primary\/10 {
  border-color: #a61f691a;
}

.bg-dark {
  --tw-bg-opacity: 1;
  background-color: rgb(50 34 73 / var(--tw-bg-opacity));
}

.bg-light {
  --tw-bg-opacity: 1;
  background-color: rgb(245 245 249 / var(--tw-bg-opacity));
}

.bg-primary {
  --tw-bg-opacity: 1;
  background-color: rgb(166 31 105 / var(--tw-bg-opacity));
}

.bg-secondary {
  --tw-bg-opacity: 1;
  background-color: rgb(159 159 198 / var(--tw-bg-opacity));
}

.bg-transparent {
  background-color: #0000;
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-white\/95 {
  background-color: #fffffff2;
}

.bg-gradient-to-r {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
}

.from-primary {
  --tw-gradient-from: #a61f69 var(--tw-gradient-from-position);
  --tw-gradient-to: #a61f6900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.to-primary {
  --tw-gradient-to: #a61f69 var(--tw-gradient-to-position);
}

.bg-\[length\:0\%_2px\] {
  background-size: 0% 2px;
}

.bg-left-bottom {
  background-position: 0 100%;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

.fill-primary {
  fill: #a61f69;
}

.fill-primary\/20 {
  fill: #a61f6933;
}

.fill-secondary {
  fill: #9f9fc6;
}

.fill-white {
  fill: #fff;
}

.stroke-primary {
  stroke: #a61f69;
}

.object-contain {
  object-fit: contain;
}

.object-cover {
  object-fit: cover;
}

.object-left-top {
  object-position: left top;
}

.p-1 {
  padding: .25rem;
}

.p-10 {
  padding: 2.5rem;
}

.p-3 {
  padding: .75rem;
}

.p-5 {
  padding: 1.25rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-20 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.pb-5 {
  padding-bottom: 1.25rem;
}

.pb-6 {
  padding-bottom: 1.5rem;
}

.pt-10 {
  padding-top: 2.5rem;
}

.pt-14 {
  padding-top: 3.5rem;
}

.pt-32 {
  padding-top: 8rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 1.25;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 1.25;
}

.text-7xl {
  font-size: 3.5rem;
  line-height: 1.1;
}

.text-xl {
  font-size: 1.3125rem;
  line-height: 1.5;
}

.font-\[500\] {
  font-weight: 500;
}

.font-bold {
  font-weight: 700;
}

.font-medium {
  font-weight: 500;
}

.uppercase {
  text-transform: uppercase;
}

.\!text-white {
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
}

.text-primary {
  --tw-text-opacity: 1;
  color: rgb(166 31 105 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.opacity-0 {
  opacity: 0;
}

.opacity-40 {
  opacity: .4;
}

.opacity-50 {
  opacity: .5;
}

.shadow {
  --tw-shadow: 10px 20px 40px 0 #0000001a;
  --tw-shadow-colored: 10px 20px 40px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.blur {
  --tw-blur: blur(8px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.grayscale {
  --tw-grayscale: grayscale(100%);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.backdrop-blur-lg {
  --tw-backdrop-blur: blur(16px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-colors {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-transform {
  transition-property: transform;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-300 {
  transition-duration: .3s;
}

.duration-500 {
  transition-duration: .5s;
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

body.hidden-scroll {
  overflow: hidden;
}

.sl-overlay {
  z-index: 1035;
  background: #322249;
  display: none;
  position: fixed;
  inset: 0;
}

.sl-wrapper {
  z-index: 1040;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.sl-wrapper * {
  box-sizing: border-box;
}

.sl-wrapper button {
  cursor: pointer;
  background: none;
  border: 0;
  padding: 0;
  font-size: 28px;
}

.sl-wrapper button:hover {
  opacity: .7;
}

.sl-wrapper .sl-close {
  z-index: 10060;
  color: #fff;
  width: 44px;
  height: 44px;
  margin-top: -14px;
  margin-right: -14px;
  font-family: system-ui;
  font-size: 3rem;
  line-height: 44px;
  display: none;
  position: fixed;
  top: 30px;
  right: 30px;
}

.sl-wrapper .sl-counter {
  z-index: 10060;
  color: #fff;
  font-size: 1rem;
  display: none;
  position: fixed;
  top: 30px;
  left: 30px;
}

.sl-wrapper .sl-download {
  text-align: center;
  z-index: 10060;
  color: #fff;
  width: 100%;
  font-size: 1rem;
  display: none;
  position: fixed;
  bottom: 5px;
}

.sl-wrapper .sl-download a {
  color: #fff;
}

.sl-wrapper .sl-navigation {
  width: 100%;
  display: none;
}

.sl-wrapper .sl-navigation button {
  text-align: center;
  z-index: 10060;
  color: #fff;
  width: 22px;
  height: 44px;
  margin-top: -22px;
  font-family: system-ui;
  line-height: 44px;
  display: block;
  position: fixed;
  top: 50%;
}

.sl-wrapper .sl-navigation button.sl-next {
  font-size: 2rem;
  right: 5px;
}

.sl-wrapper .sl-navigation button.sl-prev {
  font-size: 2rem;
  left: 5px;
}

@media (width >= 35.5em) {
  .sl-wrapper .sl-navigation button {
    width: 44px;
  }

  .sl-wrapper .sl-navigation button.sl-next {
    font-size: 3rem;
    right: 10px;
  }

  .sl-wrapper .sl-navigation button.sl-prev {
    font-size: 3rem;
    left: 10px;
  }
}

@media (width >= 50em) {
  .sl-wrapper .sl-navigation button {
    width: 44px;
  }

  .sl-wrapper .sl-navigation button.sl-next {
    font-size: 3rem;
    right: 20px;
  }

  .sl-wrapper .sl-navigation button.sl-prev {
    font-size: 3rem;
    left: 20px;
  }
}

.sl-wrapper.sl-dir-rtl .sl-navigation {
  direction: ltr;
}

.sl-wrapper .sl-image {
  -ms-touch-action: none;
  touch-action: none;
  z-index: 10000;
  position: fixed;
}

.sl-wrapper .sl-image img {
  border: 0;
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0;
  display: block;
}

@media (width >= 35.5em) {
  .sl-wrapper .sl-image img {
    border: 0;
  }
}

@media (width >= 50em) {
  .sl-wrapper .sl-image img {
    border: 0;
  }
}

.sl-wrapper .sl-image iframe {
  background: #000;
  border: 0;
}

@media (width >= 35.5em) {
  .sl-wrapper .sl-image iframe {
    border: 0;
  }
}

@media (width >= 50em) {
  .sl-wrapper .sl-image iframe {
    border: 0;
  }
}

.sl-wrapper .sl-image .sl-caption {
  color: #fff;
  background: #000c;
  padding: 10px;
  font-size: 1rem;
  display: none;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.sl-wrapper .sl-image .sl-caption.pos-top {
  top: 0;
  bottom: auto;
}

.sl-wrapper .sl-image .sl-caption.pos-outside {
  bottom: auto;
}

.sl-spinner {
  opacity: 0;
  z-index: 1007;
  -ms-animation: pulsate 1s ease-out infinite;
  border: 5px solid #333;
  border-radius: 40px;
  width: 40px;
  height: 40px;
  margin: -20px 0 0 -20px;
  animation: 1s ease-out infinite pulsate;
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
}

.sl-scrollbar-measure {
  width: 50px;
  height: 50px;
  position: absolute;
  top: -9999px;
  overflow: scroll;
}

.sl-transition {
  transition: transform .2s;
}

@keyframes pulsate {
  0% {
    opacity: 0;
    transform: scale(.1);
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: scale(1.2);
  }
}

.editor {
  font-family: davis-sans, sans-serif;
}

.editor a:not(.button):not(.quiet) {
  --tw-text-opacity: 1;
  color: rgb(159 159 198 / var(--tw-text-opacity));
}

.editor a:not(.button):not(.quiet):hover {
  text-underline-offset: 2px;
  text-decoration-line: underline;
  transition-duration: .3s;
}

.editor ul:not(.sexy-list), .editor ol {
  padding-left: 0;
  list-style-type: none;
}

.editor ul:not(.sexy-list) li, .editor ol li {
  margin-bottom: .5rem;
}

.editor ul:not(.sexy-list) li:last-child, .editor ol li:last-child {
  margin-bottom: 0;
}

.editor ul:not(.sexy-list):not(.nav) li {
  padding-left: 1.25rem;
  position: relative;
}

.editor ul:not(.sexy-list):not(.nav) li:before {
  --tw-bg-opacity: 1;
  background-color: rgb(166 31 105 / var(--tw-bg-opacity));
  --tw-content: "";
  content: var(--tw-content);
  border-radius: 9999px;
  width: .4em;
  height: .4em;
  position: absolute;
  top: .5em;
  left: 0;
}

.editor ul:not(.sexy-list):not(.nav) ul {
  padding-top: .5rem;
}

.editor ol {
  counter-reset: ol;
}

.editor ol li:before {
  padding-right: .5rem;
}

.editor ol > li {
  counter-increment: ol;
}

.editor ol > li:before {
  content: counter(ol) ".";
}

.editor ol ol {
  counter-reset: ol2;
  padding-top: .5rem;
}

.editor ol ol > li {
  counter-increment: ol2;
  padding-left: 1.25rem;
}

.editor ol ol > li:before {
  content: counter(ol) "." counter(ol2) ".";
}

.editor ol ol ol {
  counter-reset: ol3;
}

.editor ol ol ol > li {
  counter-increment: ol3;
  padding-left: 2.5rem;
}

.editor ol ol ol > li:before {
  content: counter(ol) "." counter(ol2) "." counter(ol3) ".";
}

.editor .menu-item {
  margin-right: .5rem;
  padding-left: 0;
}

.editor .menu-item:before {
  content: var(--tw-content);
  display: none;
}

.editor blockquote {
  border-color: #a61f6933;
  border-left-width: 1px;
  padding-top: .75rem;
  padding-bottom: .75rem;
  padding-left: 1.25rem;
}

.editor .aligncenter {
  margin-left: auto;
  margin-right: auto;
}

.editor .alignleft, .editor .alignright {
  max-width: 50%;
}

.editor .alignleft {
  float: left;
  margin-right: 1.25rem;
}

.editor .alignright {
  float: right;
  margin-left: 1.25rem;
}

.editor figcaption {
  margin-top: .25rem;
  font-size: .75rem;
  line-height: 1rem;
}

.editor table {
  border-collapse: collapse;
  width: 100%;
}

.editor table td, .editor table th {
  text-align: left;
  border-width: 1px;
  border-color: #a61f6933;
  padding: 1.25rem;
}

.editor table th {
  font-weight: 700;
}

.editor table > tr:first-child td, .editor table > tr:first-child th, .editor table > tbody:first-child tr:first-child td, .editor table > tbody:first-child tr:first-child th {
  background-color: #a61f691a;
  font-weight: 700;
}

.editor hr {
  border-color: #a61f6933;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.editor img {
  border-radius: 10px;
}

.editor > p:not(:empty), .editor > ul:not(:empty), .editor > ol:not(:empty), .editor > blockquote:not(:empty), .editor > table, .editor > img, .editor > figure {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.editor > p:not(:empty):first-child, .editor > ul:not(:empty):first-child, .editor > ol:not(:empty):first-child, .editor > blockquote:not(:empty):first-child, .editor > table:first-child, .editor > img:first-child, .editor > figure:first-child {
  margin-top: 0;
}

.editor > p:not(:empty):last-child, .editor > ul:not(:empty):last-child, .editor > ol:not(:empty):last-child, .editor > blockquote:not(:empty):last-child, .editor > table:last-child, .editor > img:last-child, .editor > figure:last-child {
  margin-bottom: 0;
}

.editor h2:not([class]) {
  --tw-text-opacity: 1;
  color: rgb(166 31 105 / var(--tw-text-opacity));
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.25;
}

.editor h2:not([class]):first-child {
  margin-top: 0;
}

.editor h2:not([class]):last-child {
  margin-bottom: 0;
}

@media (width >= 1024px) {
  .editor h2:not([class]) {
    font-size: 1.75rem;
    line-height: 1.25;
  }
}

@media (width >= 1280px) {
  .editor h2:not([class]) {
    font-size: 2.25rem;
    line-height: 1.25;
  }
}

.editor h3:not([class]) {
  --tw-text-opacity: 1;
  color: rgb(166 31 105 / var(--tw-text-opacity));
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  font-size: 1.3125rem;
  font-weight: 700;
  line-height: 1.5;
}

.editor h3:not([class]):first-child {
  margin-top: 0;
}

.editor h3:not([class]):last-child {
  margin-bottom: 0;
}

@media (width >= 1280px) {
  .editor h3:not([class]) {
    font-size: 1.5rem;
    line-height: 1.25;
  }
}

.editor h4:not([class]) {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.75rem;
}

.editor h4:not([class]):first-child {
  margin-top: 0;
}

.editor h4:not([class]):last-child {
  margin-bottom: 0;
}

@media (width >= 1024px) {
  .editor h4:not([class]) {
    font-size: 1.3125rem;
    line-height: 1.5;
  }
}

body {
  color: #322249;
  --fui-font: davis-sans, sans-serif;
  --fui-primary-color: #a61f69;
  --fui-primary-color-hover: #322249;
  --fui-border-radius: 5px;
  --fui-gray-700: #322249;
  --fui-label-font-size: 1rem;
  --fui-label-font-weight: 400;
  --fui-btn-font-size: 1.125rem;
  background: #fff;
  font-size: 1rem;
}

@media (width >= 1024px) {
  body {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
}

body ::selection {
  background-color: #f5f5f9;
}

.gr {
  grid-template-columns: repeat(12, minmax(0, 1fr));
  gap: 1.25rem .5rem;
  display: grid;
}

@media (width >= 768px) {
  .gr {
    gap: 2.5rem;
  }
}

.ctn {
  padding-left: 20px;
  padding-right: 20px;
}

@media (width >= 640px) {
  .ctn {
    padding-left: 7vw;
    padding-right: 7vw;
  }
}

@media (width >= 1600px) {
  .ctn {
    padding-left: calc(50vw - 676px);
    padding-right: calc(50vw - 676px);
  }
}

svg {
  max-width: 100%;
  height: auto;
}

.tns-ovh {
  overflow: visible !important;
}

.sl-wrapper .sl-image {
  border-radius: 10px;
  overflow: hidden;
}

.fui-field:not(.fui-type-hidden-field) {
  min-width: 250px;
}

.before\:absolute:before {
  content: var(--tw-content);
  position: absolute;
}

.before\:bottom-full:before {
  content: var(--tw-content);
  bottom: 100%;
}

.before\:left-0:before {
  content: var(--tw-content);
  left: 0;
}

.before\:h-5:before {
  content: var(--tw-content);
  height: 1.25rem;
}

.before\:w-full:before {
  content: var(--tw-content);
  width: 100%;
}

.before\:bg-transparent:before {
  content: var(--tw-content);
  background-color: #0000;
}

.after\:inline:after {
  content: var(--tw-content);
  display: inline;
}

.after\:break-before-avoid:after {
  content: var(--tw-content);
  break-before: avoid;
}

.after\:align-middle:after {
  content: var(--tw-content);
  vertical-align: middle;
}

.after\:text-primary:after {
  content: var(--tw-content);
  --tw-text-opacity: 1;
  color: rgb(166 31 105 / var(--tw-text-opacity));
}

.last\:mb-0:last-child {
  margin-bottom: 0;
}

.hover\:cursor-pointer:hover {
  cursor: pointer;
}

.hover\:bg-dark:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(50 34 73 / var(--tw-bg-opacity));
}

.hover\:bg-primary:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(166 31 105 / var(--tw-bg-opacity));
}

.hover\:text-primary:hover {
  --tw-text-opacity: 1;
  color: rgb(166 31 105 / var(--tw-text-opacity));
}

.hover\:underline:hover {
  text-decoration-line: underline;
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.group:hover .group-hover\:translate-x-4 {
  --tw-translate-x: 1rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group:hover .group-hover\:opacity-100 {
  opacity: 1;
}

.group:hover .group-hover\:grayscale-0 {
  --tw-grayscale: grayscale(0);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.group.is-active .group-\[\.is-active\]\:pointer-events-auto {
  pointer-events: auto;
}

.group\/header.is-opened .group-\[\.is-opened\]\/header\:z-\[1000\] {
  z-index: 1000;
}

.group.is-active .group-\[\.is-active\]\:translate-y-0 {
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group.menu-is-opened .group-\[\.menu-is-opened\]\:scale-x-0 {
  --tw-scale-x: 0;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@keyframes burgerBottomReverse {
  0% {
    transform: translateY(-7px)rotate(45deg);
  }

  50% {
    transform: translateY(-7px)rotate(0);
  }

  100% {
    transform: translateY(0)rotate(0);
  }
}

.group.menu-is-closed .group-\[\.menu-is-closed\]\:animate-burger-bottom-reverse {
  animation: .5s ease-in-out forwards burgerBottomReverse;
}

@keyframes burgerTopReverse {
  0% {
    transform: translateY(7px)rotate(-45deg);
  }

  50% {
    transform: translateY(7px)rotate(0);
  }

  100% {
    transform: translateY(0)rotate(0);
  }
}

.group.menu-is-closed .group-\[\.menu-is-closed\]\:animate-burger-top-reverse {
  animation: .5s ease-in-out forwards burgerTopReverse;
}

@keyframes burgerBottom {
  0% {
    transform: translateY(0)rotate(0);
  }

  50% {
    transform: translateY(-7px)rotate(0);
  }

  100% {
    transform: translateY(-7px)rotate(45deg);
  }
}

.group.menu-is-opened .group-\[\.menu-is-opened\]\:animate-burger-bottom {
  animation: .5s ease-in-out forwards burgerBottom;
}

@keyframes burgerTop {
  0% {
    transform: translateY(0)rotate(0);
  }

  50% {
    transform: translateY(7px)rotate(0);
  }

  100% {
    transform: translateY(7px)rotate(-45deg);
  }
}

.group.menu-is-opened .group-\[\.menu-is-opened\]\:animate-burger-top {
  animation: .5s ease-in-out forwards burgerTop;
}

.group\/header.scrolled .group-\[\.scrolled\]\/header\:py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.group.is-active .group-\[\.is-active\]\:opacity-100 {
  opacity: 1;
}

.group\/header.is-opened .group-\[\.is-opened\]\/header\:transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.group.menu-is-closed .group-\[\.menu-is-closed\]\:delay-300 {
  transition-delay: .3s;
}

.group.menu-is-opened .group-\[\.menu-is-opened\]\:delay-100 {
  transition-delay: .1s;
}

.group\/header.is-opened .group-\[\.is-opened\]\/header\:duration-500 {
  transition-duration: .5s;
}

.aria-disabled\:opacity-50[aria-disabled="true"], .aria-hidden\:opacity-50[aria-hidden="true"] {
  opacity: .5;
}

@media (width >= 640px) {
  .sm\:col-span-1 {
    grid-column: span 1 / span 1;
  }

  .sm\:col-span-4 {
    grid-column: span 4 / span 4;
  }

  .sm\:col-span-6 {
    grid-column: span 6 / span 6;
  }

  .sm\:col-span-8 {
    grid-column: span 8 / span 8;
  }

  .sm\:col-start-4 {
    grid-column-start: 4;
  }

  .sm\:h-16 {
    height: 4rem;
  }

  .sm\:w-auto {
    width: auto;
  }

  .sm\:min-w-full {
    min-width: 100%;
  }

  .sm\:p-10 {
    padding: 2.5rem;
  }

  .sm\:px-\[7vw\] {
    padding-left: 7vw;
    padding-right: 7vw;
  }
}

@media (width >= 768px) {
  .md\:absolute {
    position: absolute;
  }

  .md\:bottom-full {
    bottom: 100%;
  }

  .md\:order-1 {
    order: 1;
  }

  .md\:order-2 {
    order: 2;
  }

  .md\:col-span-1 {
    grid-column: span 1 / span 1;
  }

  .md\:col-span-10 {
    grid-column: span 10 / span 10;
  }

  .md\:col-span-3 {
    grid-column: span 3 / span 3;
  }

  .md\:col-span-4 {
    grid-column: span 4 / span 4;
  }

  .md\:col-span-5 {
    grid-column: span 5 / span 5;
  }

  .md\:col-span-6 {
    grid-column: span 6 / span 6;
  }

  .md\:col-span-7 {
    grid-column: span 7 / span 7;
  }

  .md\:col-span-8 {
    grid-column: span 8 / span 8;
  }

  .md\:col-start-2 {
    grid-column-start: 2;
  }

  .md\:col-start-3 {
    grid-column-start: 3;
  }

  .md\:col-start-6 {
    grid-column-start: 6;
  }

  .md\:my-24 {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }

  .md\:-ml-10 {
    margin-left: -2.5rem;
  }

  .md\:mb-12 {
    margin-bottom: 3rem;
  }

  .md\:mb-16 {
    margin-bottom: 4rem;
  }

  .md\:mt-0 {
    margin-top: 0;
  }

  .md\:mt-12 {
    margin-top: 3rem;
  }

  .md\:mt-24 {
    margin-top: 6rem;
  }

  .md\:mt-9 {
    margin-top: 2.25rem;
  }

  .md\:h-full {
    height: 100%;
  }

  .md\:min-h-\[80vh\] {
    min-height: 80vh;
  }

  .md\:w-7\/12 {
    width: 58.3333%;
  }

  .md\:min-w-\[100px\] {
    min-width: 100px;
  }

  .md\:justify-start {
    justify-content: flex-start;
  }

  .md\:overflow-auto {
    overflow: auto;
  }

  .md\:rounded-none {
    border-radius: 0;
  }

  .md\:p-10 {
    padding: 2.5rem;
  }

  .md\:py-24 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .md\:pb-32 {
    padding-bottom: 8rem;
  }

  .md\:pl-10 {
    padding-left: 2.5rem;
  }

  .md\:pt-48 {
    padding-top: 12rem;
  }

  .md\:text-left {
    text-align: left;
  }

  .md\:text-5xl {
    font-size: 2.5rem;
    line-height: 1.2;
  }
}

@media (width >= 1024px) {
  .lg\:pointer-events-auto {
    pointer-events: auto;
  }

  .lg\:visible {
    visibility: visible;
  }

  .lg\:static {
    position: static;
  }

  .lg\:absolute {
    position: absolute;
  }

  .lg\:relative {
    position: relative;
  }

  .lg\:\!col-span-4 {
    grid-column: span 4 / span 4 !important;
  }

  .lg\:col-span-3 {
    grid-column: span 3 / span 3;
  }

  .lg\:col-span-4 {
    grid-column: span 4 / span 4;
  }

  .lg\:col-span-5 {
    grid-column: span 5 / span 5;
  }

  .lg\:col-span-6 {
    grid-column: span 6 / span 6;
  }

  .lg\:col-span-8 {
    grid-column: span 8 / span 8;
  }

  .lg\:col-start-2 {
    grid-column-start: 2;
  }

  .lg\:col-start-3 {
    grid-column-start: 3;
  }

  .lg\:col-start-4 {
    grid-column-start: 4;
  }

  .lg\:float-left {
    float: left;
  }

  .lg\:my-28 {
    margin-top: 7rem;
    margin-bottom: 7rem;
  }

  .lg\:mt-2 {
    margin-top: .5rem;
  }

  .lg\:mt-28 {
    margin-top: 7rem;
  }

  .lg\:mt-3 {
    margin-top: .75rem;
  }

  .lg\:flex {
    display: flex;
  }

  .lg\:hidden {
    display: none;
  }

  .lg\:h-auto {
    height: auto;
  }

  .lg\:w-auto {
    width: auto;
  }

  .lg\:w-full {
    width: 100%;
  }

  .lg\:min-w-\[200px\] {
    min-width: 200px;
  }

  .lg\:max-w-\[50\%\] {
    max-width: 50%;
  }

  .lg\:max-w-none {
    max-width: none;
  }

  .lg\:grow {
    flex-grow: 1;
  }

  .lg\:translate-y-0 {
    --tw-translate-y: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:flex-nowrap {
    flex-wrap: nowrap;
  }

  .lg\:items-center {
    align-items: center;
  }

  .lg\:justify-start {
    justify-content: flex-start;
  }

  .lg\:gap-x-5 {
    column-gap: 1.25rem;
  }

  .lg\:overflow-visible {
    overflow: visible;
  }

  .lg\:p-0 {
    padding: 0;
  }

  .lg\:p-10 {
    padding: 2.5rem;
  }

  .lg\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .lg\:py-28 {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }

  .lg\:py-3 {
    padding-top: .75rem;
    padding-bottom: .75rem;
  }

  .lg\:pb-2 {
    padding-bottom: .5rem;
  }

  .lg\:pr-10 {
    padding-right: 2.5rem;
  }

  .lg\:pt-1 {
    padding-top: .25rem;
  }

  .lg\:text-3xl {
    font-size: 1.75rem;
    line-height: 1.25;
  }

  .lg\:text-5xl {
    font-size: 2.5rem;
    line-height: 1.2;
  }

  .lg\:text-6xl {
    font-size: 3rem;
    line-height: 1.1;
  }

  .lg\:shadow {
    --tw-shadow: 10px 20px 40px 0 #0000001a;
    --tw-shadow-colored: 10px 20px 40px 0 var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:hover\:bg-\[length\:100\%_2px\]:hover {
    background-size: 100% 2px;
  }
}

@media (width >= 1280px) {
  .xl\:col-span-5 {
    grid-column: span 5 / span 5;
  }

  .xl\:col-span-7 {
    grid-column: span 7 / span 7;
  }

  .xl\:col-span-8 {
    grid-column: span 8 / span 8;
  }

  .xl\:col-start-3 {
    grid-column-start: 3;
  }

  .xl\:mr-5 {
    margin-right: 1.25rem;
  }

  .xl\:gap-x-8 {
    column-gap: 2rem;
  }

  .xl\:pl-10 {
    padding-left: 2.5rem;
  }

  .xl\:text-2xl {
    font-size: 1.5rem;
    line-height: 1.25;
  }

  .xl\:text-5xl {
    font-size: 2.5rem;
    line-height: 1.2;
  }

  .xl\:text-6xl {
    font-size: 3rem;
    line-height: 1.1;
  }
}

@media (width >= 1536px) {
  .\32 xl\:col-span-5 {
    grid-column: span 5 / span 5;
  }

  .\32 xl\:col-span-6 {
    grid-column: span 6 / span 6;
  }

  .\32 xl\:col-start-2 {
    grid-column-start: 2;
  }

  .\32 xl\:col-start-4 {
    grid-column-start: 4;
  }
}

@media (width <= 1023px) {
  .mobile\:top-0 {
    top: 0;
  }

  .mobile\:z-20 {
    z-index: 20;
  }

  .mobile\:flex {
    display: flex;
  }

  .mobile\:h-0 {
    height: 0;
  }

  .mobile\:w-full {
    width: 100%;
  }

  .mobile\:justify-between {
    justify-content: space-between;
  }

  .mobile\:divide-y > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
  }

  .mobile\:divide-solid > :not([hidden]) ~ :not([hidden]) {
    border-style: solid;
  }

  .mobile\:divide-primary\/10 > :not([hidden]) ~ :not([hidden]) {
    border-color: #a61f691a;
  }

  .mobile\:overflow-hidden {
    overflow: hidden;
  }

  .mobile\:overflow-scroll {
    overflow: scroll;
  }

  .mobile\:py-3 {
    padding-top: .75rem;
    padding-bottom: .75rem;
  }

  .mobile\:pb-16 {
    padding-bottom: 4rem;
  }

  .mobile\:transition-opacity {
    transition-property: opacity;
    transition-duration: .15s;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  }

  .group\/header.is-opened .mobile\:group-\[\.is-opened\]\/header\:z-50 {
    z-index: 50;
  }

  .group.is-active .mobile\:group-\[\.is-active\]\:mt-2 {
    margin-top: .5rem;
  }

  .group.is-active .mobile\:group-\[\.is-active\]\:h-auto {
    height: auto;
  }

  .group\/header.is-opened .mobile\:group-\[\.is-opened\]\/header\:h-full {
    height: 100%;
  }

  .group\/header.is-opened .mobile\:group-\[\.is-opened\]\/header\:translate-y-0 {
    --tw-translate-y: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .group\/header.is-opened .mobile\:group-\[\.is-opened\]\/header\:flex-col {
    flex-direction: column;
  }

  .group\/header.is-opened .mobile\:group-\[\.is-opened\]\/header\:bg-light {
    --tw-bg-opacity: 1;
    background-color: rgb(245 245 249 / var(--tw-bg-opacity));
  }

  .group.is-active .mobile\:group-\[\.is-active\]\:p-3 {
    padding: .75rem;
  }

  .group.is-active .mobile\:group-\[\.is-active\]\:px-3 {
    padding-left: .75rem;
    padding-right: .75rem;
  }

  .group\/header.is-opened .mobile\:group-\[\.is-opened\]\/header\:shadow {
    --tw-shadow: 10px 20px 40px 0 #0000001a;
    --tw-shadow-colored: 10px 20px 40px 0 var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
}

.\[\&\.is-hidden\.scrolled\]\:-translate-y-full.is-hidden.scrolled {
  --tw-translate-y: -100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.\[\&\.scrolled\]\:border-b.scrolled {
  border-bottom-width: 1px;
}

.\[\&\.scrolled\]\:bg-white.scrolled {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.\[\&\.tns-nav-active\]\:opacity-100.tns-nav-active {
  opacity: 1;
}

.\[\&\>\.float-left\+p\]\:mt-0 > .float-left + p {
  margin-top: 0;
}

@media (width >= 1024px) {
  .lg\:\[\&\>\.float-left\+p\]\:float-left > .float-left + p {
    float: left;
  }
}

@media (width <= 767px) {
  .\[\&\>p\]\:xs\:\!text-center > p {
    text-align: center !important;
  }
}

.\[\&_\.flex\]\:justify-center .flex {
  justify-content: center;
}

@media (width >= 640px) {
  .sm\:\[\&_\.flex\]\:justify-start .flex {
    justify-content: flex-start;
  }
}

.\[\&_a\:hover\]\:text-primary a:hover {
  --tw-text-opacity: 1;
  color: rgb(166 31 105 / var(--tw-text-opacity));
}

.\[\&_img\]\:mx-auto img {
  margin-left: auto;
  margin-right: auto;
}

@media (width >= 640px) {
  .sm\:\[\&_img\]\:mx-0 img {
    margin-left: 0;
    margin-right: 0;
  }
}

.\[\&_svg\]\:mx-auto svg {
  margin-left: auto;
  margin-right: auto;
}

.\[\&_svg\]\:h-10 svg {
  height: 2.5rem;
}

.\[\&_svg\]\:w-full svg {
  width: 100%;
}

.\[\&_svg\]\:fill-white svg {
  fill: #fff;
}

.\[\&_svg\]\:stroke-primary svg {
  stroke: #a61f69;
}

@media (width >= 640px) {
  .sm\:\[\&_svg\]\:mx-0 svg {
    margin-left: 0;
    margin-right: 0;
  }
}

.bg-light + .\[\.bg-light\+\&\.bg-light\]\:\!pt-0.bg-light {
  padding-top: 0 !important;
}

:not(.bg-transparent) + .\[\:not\(\.bg-transparent\)\+\&\]\:\!mt-0, header + .\[header\+\&\]\:\!mt-0 {
  margin-top: 0 !important;
}

/*# sourceMappingURL=main.css.map */
